import { removeLeadingSlash } from "@/utils/stringUtils.js";

export async function tryFn(fn) {
  try {
    await fn();
  } catch (err) {
    console.error(err);
  }
}

export function toUrl(path) {
  if (!path) {
    return '';
  }

  const isFullUrl = /^(http|https):\/\//.test(path);
  if (isFullUrl) {
    return path;
  }

  const availableLocales = ['en', 'ja'];
  const localeInPath = availableLocales.includes(path.split('/').filter(Boolean)[0]);
  if (localeInPath) {
    return path;
  }

  const currentLocale = window.location.pathname.split('/').filter(Boolean)[0];
  const locale = availableLocales.includes(currentLocale) ? currentLocale : 'en';
  const relPath = removeLeadingSlash(path);
  const useLocale = !relPath.startsWith('admin');
  return useLocale ? `/${locale}/${relPath}` : `/${relPath}`;
}

export function redirectTo(path, target = '_self') {
  return window.open(toUrl(path), target);
}